const kontakt = {
  title: "Kontakt",
  company: "OH, JA!",
  firstname: "Philipp",
  lastname: "von Mauch",
  address: {
    street: "Rumfordstraße 5",
    plz: "80469",
    location: "München",
    country: "Deutschland",
  },
  email: "hallo@oh-ja.com",
  phone: "089 / 92 58 30 60",
  mobile: "+49 170 112 1337",
  fax: "xxxx",
  googlemaps: "https://goo.gl/maps/Dr2QracSwjBWjprb8",
  instagram: "https://www.instagram.com/oh___ja",
  behance: "https://www.behance.net/oh-ja",
};

export default kontakt;
