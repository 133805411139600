<template>
  <div class="thelogo">
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 109 29"
      style="enable-background:new 0 0 109 29;"
      xml:space="preserve"
    >
      <path
        class="thelogo__color"
        d="M12.1,1.4C9.3,1.4,6.6,2.3,4.4,4C1.7,6.2,0,9.5,0,13c-0.2,6.4,4.9,11.8,11.3,12c0.1,0,0.2,0,0.3,0
	c0.1,0,0.2,0,0.3,0c6.9,0,12.2-5.1,12.2-11.8c0-2.8-1-5.6-2.9-7.8C19,2.8,15.6,1.3,12.1,1.4z M17.8,13.2c0,3.2-2.6,5.8-5.8,5.9
	c-1.6,0-3-0.6-4.1-1.8s-1.7-2.6-1.7-4.2c0-3.1,2.6-5.7,5.8-5.8C15.3,7.4,17.9,10,17.8,13.2z M47.4,2.3V24c0,0.1-0.1,0.2-0.2,0.2
	h-5.5c-0.1,0-0.2-0.1-0.2-0.2v-7.3h-7.5V24c0,0.1-0.1,0.2-0.2,0.2H28c-0.1,0-0.2-0.1-0.2-0.2V2.3c0-0.1,0.1-0.2,0.2-0.2h5.5
	c0.1,0,0.2,0.1,0.2,0.2v9.4h7.5V2.3c0-0.1,0.1-0.2,0.2-0.2h5.5C47.3,2.1,47.4,2.2,47.4,2.3z M81.7,2.4L73,25.1
	c0,0.1-0.1,0.1-0.2,0.2c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2-0.1L61.2,13.5c-0.1-0.1-0.1-0.2,0-0.4l3.9-4c0.1-0.1,0.1-0.1,0.2-0.1
	c0.1,0,0.1,0,0.2,0.1l5.1,5.2L76,0.2c0-0.1,0.1-0.1,0.1-0.1s0.1,0,0.2,0l5.2,2C81.7,2.1,81.7,2.3,81.7,2.4z M92.8,2.2
	c0-0.1-0.1-0.2-0.2-0.2h-6.1c-0.1,0-0.2,0.1-0.2,0.2l-8.2,21.7c0,0.1,0,0.2,0,0.2c0.1,0.1,0.1,0.1,0.2,0.1h5.9
	c0.1,0,0.2-0.1,0.2-0.2l1.4-3.9h7.3l1.3,3.9c0,0.1,0.1,0.2,0.2,0.2h5.9c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0-0.2L92.8,2.2z
	M91.4,15.4h-3.9l2-5.6L91.4,15.4z M55.7,22.1c0,2.7-1.3,5.3-3.5,6.9c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1l-1.7-2
	c0-0.1-0.1-0.1-0.1-0.2s0.1-0.1,0.1-0.2c0.8-0.4,1.4-1.1,1.7-1.9c-1.2-0.4-2-1.5-2-2.8c0-1.6,1.3-2.9,2.9-3c0.1,0,0.2,0,0.3,0
	C54.7,19.1,55.8,20.5,55.7,22.1z M109,21.2c0,1.9-1.5,3.4-3.4,3.4l0,0l0,0c-1.8,0-3.3-1.5-3.3-3.4c0-1.9,1.5-3.4,3.3-3.4
	C107.5,17.8,109,19.4,109,21.2z M103.1,16.4l-0.4-15.4c0-0.1,0-0.1,0.1-0.2s0.1-0.1,0.2-0.1h5.5c0.1,0,0.1,0,0.2,0.1
	s0.1,0.1,0.1,0.2l-0.4,15.4c0,0.1-0.1,0.2-0.2,0.2h-4.7C103.2,16.6,103.1,16.5,103.1,16.4z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.thelogo__svg {
  width: 100%;
  height: 100%;
}
.thelogo__color {
  fill: white;
}

.thelogo {
  &.isblack {
    .thelogo__color {
      fill: black;
    }
  }
}
</style>
